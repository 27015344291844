<template>
    <v-card>
        <!--<v-img
            height="700"
            :src="cimage">
        </v-img>-->
        <canvas id="previewContainer">
        </canvas>
        <v-card-title class="mx-1 pb-0">
            <v-slider v-model="model"
                min="0"
                :max="max"
                append-icon="mdi-step-forward"
                prepend-icon="mdi-step-backward"
                @click:append="forward"
                @click:prepend="backward"
                @input="updateImage"
                @end="preloadImages">
            </v-slider>
        </v-card-title>
        <v-card-title class="mx-1 pt-0 text-subtitle-1">
            Archive from: &nbsp;<strong>{{ currentDatetime }}</strong>
        </v-card-title>
    </v-card>
</template>


<script>
import { mapState } from 'vuex'
import { getToken } from '../_helpers/auth'

const moment = require('moment-timezone')
const axios = require('axios')

const cancelTokenSource = axios.CancelToken.source()


export default {
    props: {
        systemId: {
            type: Number,
        },
        siteviewId: {
            type: Number,
        },
        lastImageAt: {
            type: String,
        },
    },

    data() {
        return {
            model: 100,
            interval: null,
            max: 7 * 24 * 6,
            image: new Image(),
            canvasContext: null,
            imageDate: moment(),
            maxPreload: 50,
            refTable: {},
        }
    },

    computed: {
        ...mapState({
            account: 'account',
        }),

        cimage() {
            const now = moment().subtract(this.max - this.model, 'hour')
            // const minute = ((now.minute() - (now.minute() % this.interval)).toString() ).padStart(2, 0)

            return `${process.env.VUE_APP_REST_DOMAIN}/archive/${this.systemId}?month=${now.month() + 1}&day=${now.date()}&hour=${now.hour()}&minute=${0}&interval=${this.interval}&token=${getToken()}`
        },

        currentDatetime() {
            // const now = moment().subtract(this.max - this.model, 'hour')
            let result
            if (this.imageDate !== undefined) {
                result = this.imageDate ? this.imageDate.format('YYYY-MM-DD HH:mm') : 'unknown'
            } else {
                result = this.imageDate.format('YYYY-MM-DD HH:mm')
            }

            return result
        },
    },

    methods: {
        async preloadImages() {
            let start = this.model + Math.ceil(this.maxPreload / 2)
            if (start > this.max) start = this.max
            let end = start - this.maxPreload
            if (end < 0) end = 0

            for (let i = start; i > end; i -= 1) {
                const img = new Image()
                await this.setImage(i, img)
            }
        },

        currentFrame(index) {
            const now = moment().subtract(this.max - index, 'hour')
            // const url = `${process.env.VUE_APP_REST_DOMAIN}/archive/${this.systemId}?month=${now.month()+1}&day=${now.date()}&hour=${now.hour()}&minute=${0}&interval=${this.interval}&token=${getToken()}`
            const url = `${process.env.VUE_APP_REST_DOMAIN}/archive/${this.systemId}?token=${getToken()}`
            const body = {
                offset: this.max - index,
                siteview: null,
            }

            if (this.interval) {
                body.interval = this.interval
            }

            if (this.lastImageAt) {
                body.last = this.lastImageAt
            }

            if (this.siteviewId) {
                body.siteview = this.siteviewId
            }
            return { url, body }
        },

        updateImage(index) {
            // cancelTokenSource.cancel('Canceled by user.')
            // cancelTokenSource = axios.CancelToken.source()
            requestAnimationFrame(() => {
                this.setImage(index, this.image, cancelTokenSource.token)
                    .then(datetime => {
                        if (datetime !== undefined) {
                            this.imageDate = datetime
                        }
                    })
            })
        },

        setImage(index, img, cancelToken) {
            const { url, body } = this.currentFrame(index)
            if (this.refTable[body.offset]) {
                img.src = this.refTable[body.offset].src
                return Promise.resolve(this.refTable[body.offset].createdAt)
            }

            let createdAt
            const options = {
                timeout: 3000,
            }
            if (cancelToken) {
                options.cancelToken = cancelToken
            }

            return axios.post(url, body, options)
                .then(resp => {
                    createdAt = resp.data.createdAt
                    if (resp.data.success) {
                        // console.log(`${process.env.VUE_APP_REST_DOMAIN}/${resp.data.image}?token=${getToken()}&num${index}`)
                        img.src = `${process.env.VUE_APP_REST_DOMAIN}/${resp.data.image}?token=${getToken()}&num${index}`
                        this.refTable[body.offset] = {
                            src: `${process.env.VUE_APP_REST_DOMAIN}/${resp.data.image}?token=${getToken()}&num${index}`,
                            createdAt: createdAt ? moment(createdAt) : createdAt,
                        }
                    }
                    this.interval = resp.data.interval

                    return createdAt ? moment(createdAt) : createdAt
                })
                .catch(e => {
                    console.log(url, e.message)
                })
        },

        imageOnLoad() {
            const { canvas } = this.canvasContext
            const hRatio = canvas.width / this.image.width
            const vRatio = canvas.height / this.image.height
            const ratio = Math.min(hRatio, vRatio)
            const centerShiftX = (canvas.width - this.image.width * ratio) / 2
            const centerShiftY = (canvas.height - this.image.height * ratio) / 2
            this.canvasContext.drawImage(this.image, centerShiftX, centerShiftY, this.image.width * ratio, this.image.height * ratio)
        },

        forward() {
            this.model += 1
            if (this.model > this.max) this.model = this.max
            this.updateImage(this.model)
        },

        backward() {
            this.model -= 1
            if (this.model < 0) this.model = 0
            this.updateImage(this.model)
        },

        reset() {
            this.model = this.max
            this.interval = null
            this.imageDate = moment()
            this.refTable = {}

            this.setImage(this.max, this.image)
        },
    },

    created() {
        this.model = this.max
        // `${process.env.VUE_APP_REST_DOMAIN}/archive/280/?year=2022&month=2&day=18&hour=13&interval=10`
        this.preloadImages()
    },

    mounted() {
        const canvas = document.getElementById('previewContainer')
        this.canvasContext = canvas.getContext('2d')
        this.canvasContext.imageSmoothingEnabled = false

        this.canvasContext.canvas.width = 1000
        this.canvasContext.canvas.height = 750

        this.image.onload = () => {
            this.imageOnLoad()
        }

        this.setImage(this.max, this.image)
            .then(datetime => {
                if (datetime !== undefined) {
                    this.imageDate = datetime
                }
            })
    },

    watch: {
        systemId(val) {
            this.reset()
        },
    },
}
</script>

<style>
    #previewContainer {
        /*transform: translate(-50%, -50%);
        max-width: 100vw;
        max-height: 100vh;*/
        width:100%;
    }
</style>
